import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  { 
    path: '/',
    component: () => import('../views/HomePage.vue')
  },
  { 
    path: '/Login',
    component: () => import('../views/SigninPage.vue')
  },
  {
    path: '/Registration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RegistrationPage.vue')
  },
  { 
    path: '/TrainingRequest',
    component: () => import('../views/TrainingForm.vue')
  },
  
  { 
    path: '/AboutUs',
    component: () => import('../views/AboutPage.vue')
  },

  { 
    path: '/News',
    component: () => import('../views/NewsPage.vue')
  },

  { 
    path: '/RequestTransper',
    component: () => import('../views/AdOpsPage/PatientTransferPage.vue')
  },

  { 
    path: '/Profile',
    component: () => import('../views/Userprofilepage.vue'),
    meta:{requiredAuth: true}
  },

  { 
    path: '/RPATAdminDashBoard',
    component: () => import('../views/Adminside/RPATAdminPage.vue'),
    meta:{requiredAuth: true}
    
  },

  { 
    path: '/RPATAdminDashBoard/TrainingRequest',
    component: () => import('../views/Adminside/RPATAdminRequestPage.vue'),
    meta:{requiredAuth: true}
    
  },

  { 
    path: '/RPATAdminDashBoard/AcceptedRequest',
    component: () => import('../views/Adminside/RPATAdminAccepTPage.vue'),
    meta:{requiredAuth: true}
  },

  { 
    path: '/RPATAdminDashBoard/CompletedTraining',
    component: () => import('../views/Adminside/RPATAdminTCompletePage.vue'),
    meta:{requiredAuth: true}
    
  },

  {
    path: '/RPATAdminDashBoard/RPATinventory',
    component: () => import('../views/Adminside/RPATinventoryPage.vue'),
    meta:{requiredAuth: true}
    
  },


  { 
    path: '/OpsdivAdminDashBoard/Inventory',
    component: () => import('../views/AdOpsPage/InventoryPage.vue'),
    meta:{requiredAuth: true}

  },
  
  { 
    path: '/OpsdivAdminDashBoard/patientAccept',
    component: () => import('../views/AdOpsPage/patientAcceptPage.vue'),
    meta:{requiredAuth: true}
    
  },

  { 
    path: '/OpsdivAdminDashBoard/standbyMedicAccept',
    component: () => import('../views/AdOpsPage/standbyMedicPage.vue'),
    meta:{requiredAuth: true}
  },

  { 
    path: '/OpsdivAdminDashBoard/AdvisoryNews',
    component: () => import('../views/AdOpsPage/advisoryPage.vue'),
    meta:{requiredAuth: true}
    
  },
  
  { 
    path: '/OpsdivAdminDashBoard/DisasterReport',
    component: () => import('../views/AdOpsPage/DisasterincidentPage.vue'),
    meta:{requiredAuth: true}
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const isLoggedin = checkUserLogin();
 if (to.matched.some((record) => record.meta.requiredAuth)) {
  if (!isLoggedin) {
    next('/');
  } else {
    next( );
  }
 }else{
  next();
 }
});

 function checkUserLogin(){
  const userToken = sessionStorage.getItem("Verification_token");
  return !!userToken;}

export default router
